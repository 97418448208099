import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Col, Container, Label, Row } from 'reactstrap';
import { AsyncPaginate } from 'react-select-async-paginate';

import generateQR from './../../../../../../assets/custom/valet-parking/generate-qr.svg';
import reports from './../../../../../../assets/custom/valet-parking/reports.svg';
import manualSvg from './../../../../../../assets/custom/valet-parking/manual.svg';
import bookingSvg from './../../../../../../assets/custom/valet-parking/booking.svg';
import downloadSvg from './../../../../../../assets/custom/valet-parking/download.svg';
import { Constant } from '../../../../../Helpers/constant';
import { ksaProjectsBaseURL } from '../../../../../Config';
import { Loader } from '../../../../../Common';
import { VENDOR_GENERATE_QR, VENDOR_GENERATE_QR_EMPTY } from '../../../../../../store/actions';
import Select from 'react-select';

const themeStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    padding: '8px 15px',
    ':hover': {
      cursor: 'pointer',
    },
    borderBottom: '1px solid #80808026',
  }),

  singleValue: (provided, state) => ({
    ...provided,
    color: 'black',
    fontSize: '14px',
  }),

  placeholder: styles => ({
    ...styles,
    color: 'black',
  }),

  control: (styles, state) => ({
    ...styles,
    borderRadius: '30px',
    cursor: 'pointer',
    fontSize: '16px',
    padding: '2px 2px 2px 40px',
  }),

  menu: styles => ({
    ...styles,
    padding: '5px 0',
    margin: '5px 0',
    borderRadius: '20px',
    width: '100%',
    overflow: 'hidden',
  }),

  dropdownIndicator: styles => ({ ...styles, color: 'black' }),
  indicatorSeparator: styles => ({ ...styles, borderBottom: '1px solid black', display: 'none' }),
};

const initialValues = {
  client: null,
};

const Navigator = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [clientList, setClientList] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    // Add event listener to window resize event
    window.addEventListener('resize', checkDeviceType);
    // Initial check of device type on component mount
    checkDeviceType();
    // Cleanup function to remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkDeviceType);
    };
  }, []);

  const formattedNames = (name: string) => {
    const firstPart = name.split('_')[0]; // Get the part before the underscore
    return `${firstPart} Bank`; // Add "Bank" to the first part
  };

  useEffect(() => {
    const { clients } = JSON.parse(localStorage.getItem('authUser'));
    if (clients?.length > 0) {
      setClientList(
        clients?.map((client: string) => {
          return { value: client, label: `${formattedNames(client)}`, completeData: { code: client, name: `${formattedNames(client)}` } };
        }),
      );
    }
  }, []);

  // Generate Qr
  const { vendorGenerateQr, vendorGenerateQrLoading } = useSelector((state: any) => ({
    vendorGenerateQr: state?.VendorGenerateQr?.data,
    vendorGenerateQrLoading: state?.VendorGenerateQr?.loading,
  }));

  // Function to check if the device is a mobile device based on the screen width
  const checkDeviceType = () => {
    setIsMobile(window.innerWidth <= 767);
  };

  useEffect(() => {
    // Add event listener to window resize event
    window.addEventListener('resize', checkDeviceType);
    // Initial check of device type on component mount
    checkDeviceType();
    // Cleanup function to remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkDeviceType);
    };
  }, []);

  const loadOptionForClient = async () => {
    let urlParam = null;
    return await axios({
      method: `GET`,
      url: `${ksaProjectsBaseURL}/vendor/clients`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        // ...customHeaders,
      },
      params: {
        ...urlParam,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.data?.map(data => {
            return {
              value: data?.name,
              label: `${data?.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  };

  const onSelectClient = selectedOption => {
    setState({ ...state, client: selectedOption });
  };

  const handleManualBooking = () => {
    const authUserJson = localStorage.getItem('authUser');
    const authUser = authUserJson ? JSON.parse(authUserJson) : null;
    if (authUser && authUser.data) {
      const { firstName, lastName, userId, facilityId } = authUser.data;
      dispatch({
        type: VENDOR_GENERATE_QR,
        payload: {
          headers: {
            code: state?.client?.completeData?.code,
          },
          data: {
            vendorId: userId,
            vendorFirstName: firstName,
            vendorLastName: lastName,
            facilityId: facilityId,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (vendorGenerateQr !== null) {
      window.open(
        `${vendorGenerateQr?.url}?token=${vendorGenerateQr?.token}&bookingFlow=Manual&lang=${localStorage.getItem('I18N_LANGUAGE')}`,
        '_blank',
      );
    }
    return () => {
      dispatch({
        type: VENDOR_GENERATE_QR_EMPTY,
      });
    };
  }, [vendorGenerateQr]);

  useEffect(() => {
    if (vendorGenerateQrLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [vendorGenerateQrLoading]);

  const handleRoute = (flag: string) => {
    if (flag === 'QR') {
      history.push({
        pathname: '/vendor/qr',
        state: { code: state?.client?.completeData?.code },
      });
    } else if (flag === 'BOOKING') {
      history.push({
        pathname: '/vendor/booking/valet-parking',
        state: { code: state?.client?.completeData?.code, name: state?.client?.completeData?.name },
      });
    } else if (flag === 'REPORT') {
      history.push({
        pathname: '/vendor/report',
        state: { code: state?.client?.completeData?.code, name: state?.client?.completeData?.name },
      });
    } else if (flag === 'DOWNLOAD') {
      history.push('/vendor/download');
    }
  };

  const inputSelectHandler = (label: string, data) => {
    let updatedState = null;

    if (label === `clients`) {
      updatedState = {
        client: data,
      };
      setState(updatedState);
    }
  };

  return (
    <Container>
      {loading && <Loader />}
      <Row>
        <Col md={12}>
          <Label htmlFor="client" className="form-label fs-5">
            {t('Select Client')}
          </Label>
          <div className="mb-2 position-relative">
            <Select
              id="clients"
              name="currency"
              value={state?.client}
              options={clientList}
              onChange={data => inputSelectHandler(`clients`, data)}
              styles={themeStyles}
            />
            <div className="select-icon-start">
              <i className="ri-bank-line text-dark fs-20 align-middle me-1"></i>
            </div>
          </div>
        </Col>

        <Col md={12}>
          <div
            className="my-2 position-relative d-flex align-items-center cursor-pointer"
            onClick={() => handleRoute('QR')}
            style={{ pointerEvents: state.client === null ? 'none' : 'auto' }}
          >
            <div className="menu-start-icon-container fs-4 p-2">
              <img src={generateQR} className="img-fluid" alt="" />
            </div>
            <button
              className="w-100 d-flex justify-content-between align-items-center bg-white fs-4 px-2 py-1"
              style={{ borderRadius: '0 15px 15px 0', marginLeft: '-12px', zIndex: '-1', border: '1px solid white' }}
              disabled={state.client === null ? true : false}
            >
              <span className="d-inline-block fs-5 px-3 py-2 fw-normal">{t('Generate QR')}</span>
              <span>
                <i className="ri-arrow-right-s-line text-dark fw-semibold fs-20 align-middle me-1"></i>
              </span>
            </button>
          </div>
        </Col>

        <Col md={12}>
          <div
            className="my-2 position-relative d-flex align-items-center cursor-pointer"
            onClick={handleManualBooking}
            style={{ pointerEvents: state.client === null ? 'none' : 'auto' }}
          >
            <div className="menu-start-icon-container fs-4 p-2">
              <img src={manualSvg} className="img-fluid" alt="" />
            </div>
            <button
              className="w-100 d-flex justify-content-between align-items-center bg-white fs-4 px-2 py-1"
              style={{ borderRadius: '0 15px 15px 0', marginLeft: '-12px', zIndex: '-1', border: '1px solid white' }}
              disabled={state.client === null ? true : false}
            >
              <span className="d-inline-block fs-5 px-3 py-2 fw-normal">{t('Manual Entry')}</span>
              <span>
                <i className="ri-arrow-right-s-line text-dark fw-semibold fs-20 align-middle me-1"></i>
              </span>
            </button>
          </div>
        </Col>
        {isMobile && (
          <>
            <Col md={12}>
              <div
                className="my-2 position-relative d-flex align-items-center cursor-pointer"
                onClick={() => handleRoute('BOOKING')}
                style={{ pointerEvents: state.client === null ? 'none' : 'auto' }}
              >
                <div className="menu-start-icon-container fs-4 p-2">
                  <img src={bookingSvg} className="img-fluid" alt="" />
                </div>
                <button
                  className="w-100 d-flex justify-content-between align-items-center bg-white fs-4 px-2 py-1"
                  style={{ borderRadius: '0 15px 15px 0', marginLeft: '-12px', zIndex: '-1', border: '1px solid white' }}
                  disabled={state.client === null ? true : false}
                >
                  <span className="d-inline-block fs-5 px-3 py-2 fw-normal">{t('Bookings')}</span>
                  <span>
                    <i className="ri-arrow-right-s-line text-dark fw-semibold fs-20 align-middle me-1"></i>
                  </span>
                </button>
              </div>
            </Col>

            <Col md={12}>
              <div
                className="my-2 position-relative d-flex align-items-center cursor-pointer"
                onClick={() => handleRoute('REPORT')}
                style={{ pointerEvents: state.client === null ? 'none' : 'auto' }}
              >
                <div className="menu-start-icon-container fs-4 p-2">
                  <img src={reports} className="img-fluid" alt="" />
                </div>
                <button
                  className="w-100 d-flex justify-content-between align-items-center bg-white fs-4 px-2 py-1"
                  style={{ borderRadius: '0 15px 15px 0', marginLeft: '-12px', zIndex: '-1', border: '1px solid white' }}
                  disabled={state.client === null ? true : false}
                >
                  <span className="d-inline-block fs-5 px-3 py-2 fw-normal">{t('Reports')}</span>
                  <span>
                    <i className="ri-arrow-right-s-line text-dark fw-semibold fs-20 align-middle me-1"></i>
                  </span>
                </button>
              </div>
            </Col>

            <Col md={12}>
              <div className="my-2 position-relative d-flex align-items-center cursor-pointer" onClick={() => handleRoute('DOWNLOAD')}>
                <div className="menu-start-icon-container fs-4 p-2">
                  <img src={downloadSvg} className="img-fluid" alt="" />
                </div>
                <button
                  className="w-100 d-flex justify-content-between align-items-center bg-white fs-4 px-2 py-1"
                  style={{ borderRadius: '0 15px 15px 0', marginLeft: '-12px', zIndex: '-1', border: '1px solid white' }}
                >
                  <span className="d-inline-block fs-5 px-3 py-2 fw-normal">{t('Downloads')}</span>
                  <span>
                    <i className="ri-arrow-right-s-line text-dark fw-semibold fs-20 align-middle me-1"></i>
                  </span>
                </button>
              </div>
            </Col>
          </>
        )}
      </Row>
    </Container>
  );
};

export default Navigator;
